import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SingleCard } from "../../layouts";
import { baseAxios } from "../../utils/config.js";
import { ProductForm } from "../../components/products/product-form.js";
import notify from "devextreme/ui/notify";
import { useHistory } from "react-router-dom";

export default function NewProduct() {
  const history = useHistory();

  const [brands, setBrands] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const productId = parseInt(queryParams.get("productId"), 10);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resBrandsPromise = baseAxios.get(`/products/brands/all`);
        const resManufacturersPromsie = baseAxios.get(
          `/products/manufacturers/all`
        );
        const resCategoriesPromise = baseAxios.get(`/products/categories/all`);

        if (productId) {
          const resProductPromise = baseAxios.get(
            `/products/getById/${productId}`
          );
          const resProduct = await resProductPromise;
          const product = resProduct.data;

          const initialFormData = {
            name: product.product_name,
            manufacturerId: product.manufacturer_id,
            brandId: product.brand_id,
            barcode: product.product_upc_code,
            categoryId: product.category_id,
            ozSize: product.product_container_size / 22.53,
            price: product.product_cost,
          };
          formData.current = initialFormData;
        }

        const resBrands = await resBrandsPromise;
        setBrands(resBrands.data);
        const resManufacturers = await resManufacturersPromsie;
        setManufacturers(resManufacturers.data);
        const resCategories = await resCategoriesPromise;
        setCategories(resCategories.data);
      } catch (error) {
        console.error("Error fetching services: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const productData = formData.current;
    if (
      !productData.name ||
      !productData.manufacturerId ||
      !productData.brandId ||
      !productData.barcode ||
      !productData.categoryId ||
      !productData.ozSize ||
      productData.ozSize <= 0 ||
      !productData.price ||
      productData.price <= 0
    ) {
      notify("All data is required!", "Error", 3000);
      return;
    }
    const payload = {
      name: productData.name,
      manufacturerId: productData.manufacturerId,
      brandId: productData.brandId,
      barcode: productData.barcode,
      categoryId: productData.categoryId,
      ozSize: productData.ozSize,
      price: productData.price,
    };
    try {
      if (productId) {
        await baseAxios.put(`product/${productId}`, payload);
      } else {
        await baseAxios.post(`product`, payload);
      }
      history.goBack();
    } catch (e) {
      setLoading(false);
      if (e?.data?.error) {
        notify(e.data.error, "Error", 3000);
      } else {
        notify("Error saving product.", "Error", 3000);
      }
    }
    setLoading(false);
  };

  return (
    <div style={{ cursor: loading ? "progress" : "default" }}>
      <SingleCard title="Edit Service">
        <form className={"edit-service-form"} onSubmit={handleSubmit}>
          <ProductForm
            loading={loading}
            formData={formData}
            isEdit={!!productId}
            brands={brands}
            manufacturers={manufacturers}
            categories={categories}
          />
        </form>
      </SingleCard>
    </div>
  );
}
