import React, { useEffect, useState } from "react";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";

export const ProductForm = ({
  formData,
  loading,
  isEdit = false,
  brands,
  manufacturers,
  categories,
}) => {
  console.log(formData.current.manufacturerId);
  const [showBrandSelector, setShowBrandSelector] = useState(false);
  const [showCategorySelector, setShowCategorySelector] = useState(false);

  const [filteredBrands, setFilteredBrands] = useState();
  const [filteredCategories, setFilteredCategories] = useState();

  useEffect(() => {
    setFilteredBrands(
      formData.current.manufacturerId
        ? brands.filter(
            (x) => x.manufacturer_id == formData.current.manufacturerId
          )
        : brands
    );
    setShowBrandSelector(!!formData.current.manufacturerId);
    setFilteredCategories(
      formData.current.brandId
        ? categories.filter((x) => x.brand_id == formData.current.brandId)
        : categories
    );
    setShowCategorySelector(!!formData.current.brandId);
  }, [formData.current]);

  const fieldDataChanged = (e) => {
    console.log("??");
    if (e.dataField === "manufacturerId") {
      setFilteredBrands(
        formData.current.manufacturerId
          ? brands.filter(
              (x) => x.manufacturer_id == formData.current.manufacturerId
            )
          : brands
      );
      setFilteredCategories([]);
      if (e.value) {
        setShowBrandSelector(true);
        setShowCategorySelector(false);
      }
      formData.current.brandId = null;
      formData.current.categoryId = null;
    } else if (e.dataField === "brandId") {
      setFilteredCategories(
        formData.current.brandId
          ? categories.filter((x) => x.brand_id == formData.current.brandId)
          : categories
      );
      if (e.value) {
        setShowCategorySelector(true);
      }
      formData.current.categoryId = null;
    }
  };

  const nameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Color number",
  };
  const manufacturerEditorOptions = {
    items: manufacturers,
    valueExpr: "manufacturer_id",
    displayExpr: "manufacturer_name",
    searchEnabled: true,
    placeholder: "Manufacturer",
  };
  const brandEditorOptions = {
    items: filteredBrands,
    valueExpr: "brand_id",
    displayExpr: "brand_name",
    searchEnabled: true,
    placeholder: "Brand",
  };
  const barcodeEditorOptions = {
    stylingMode: "filled",
    placeholder: "Barcode",
  };
  const categoryEditorOptions = {
    items: filteredCategories,
    valueExpr: "category_id",
    displayExpr: "category_name",
    searchEnabled: true,
    placeholder: "Category",
  };
  const sizeEditorOptions = {
    placeholder: "Oz. Size",
    min: 0, // Ensuring the value can't be negative
    type: "dxNumberBox",
  };
  const priceEditorOptions = {
    placeholder: "Price",
    min: 0, // Ensuring the value can't be negative
    format: "$ #0.##",
    type: "dxNumberBox",
  };

  return (
    <Form
      formData={formData.current}
      onFieldDataChanged={fieldDataChanged}
      disabled={loading}
    >
      <Item
        dataField={"name"}
        editorType={"dxTextBox"}
        editorOptions={nameEditorOptions}
      >
        <Label visible={false} />
        <RequiredRule message={"Please choose a product color number"} />
      </Item>
      <Item
        dataField={"manufacturerId"}
        editorType={"dxSelectBox"}
        editorOptions={manufacturerEditorOptions}
      >
        <Label visible={false} />
        <RequiredRule message={"Please choose a manufacturer name"} />
      </Item>
      {showBrandSelector && (
        <Item
          dataField={"brandId"}
          editorType={"dxSelectBox"}
          editorOptions={brandEditorOptions}
        >
          <Label visible={false} />
          <RequiredRule message={"Please chose a brand name"} />
        </Item>
      )}
      {showCategorySelector && (
        <Item
          dataField={"categoryId"}
          editorType={"dxSelectBox"}
          editorOptions={categoryEditorOptions}
          label={{ visible: false }}
        >
          <RequiredRule message={"Category!"} />
        </Item>
      )}
      <Item
        dataField={"barcode"}
        editorType={"dxTextBox"}
        editorOptions={barcodeEditorOptions}
        label={{ visible: false }}
      >
        <RequiredRule message={"Write a barcode"} />
      </Item>
      <Item
        dataField={"ozSize"}
        editorOptions={sizeEditorOptions}
        label={{ visible: false }}
      >
        <RequiredRule />
      </Item>
      <Item
        dataField={"price"}
        editorOptions={priceEditorOptions}
        label={{ visible: false }}
      >
        <RequiredRule />
      </Item>

      <ButtonItem>
        <ButtonOptions width={"100%"} type={"default"} useSubmitBehavior={true}>
          <span className="dx-button-text">
            {loading ? (
              <LoadIndicator width={"24px"} height={"24px"} visible={true} />
            ) : isEdit ? (
              "Update Product"
            ) : (
              "Create a new Product"
            )}
          </span>
        </ButtonOptions>
      </ButtonItem>
    </Form>
  );
};
